import routesConfig from "../../routes/routesConfig";

const generateFlows = ({ auth, workflow }) => {
  return {
    larocaFlow: {
      step1: routesConfig.laroca,
      // step2: routesConfig.details,
      // step3: routesConfig.boatInfo,
      step4: routesConfig.numberPassengers,
      step5: routesConfig.date,
      step6: routesConfig.orderContacts,
      step7: routesConfig.review,
      step8: routesConfig.message
    }
  };
};
export default generateFlows;
