import React, { useEffect, useState } from "react";
import stores from "../../../stores";
import { Provider } from "mobx-react";
import AuthUserService from "../../../services/AuthUserService";
import { ToastContainer } from "react-toastify";
import PageLayout from "../PageLayout";
import jwt_decode from "jwt-decode";

const RootLayout = props => {
  const { children } = props;
  useEffect(() => {
    AuthUserService.subscribeAuthChange(async user => {
      if (user) {
        const {
          accessToken
          // email,
          // uid,
          // refreshToken,
          // emailVerified,
          // displayName,
          // isAnonymous
        } = user;

        const decodedToken = jwt_decode(accessToken);
        if (decodedToken) {
          const { roles, aud, exp, iat } = decodedToken || {};
          const additionalData = { roles, aud, exp, iat };
          stores.auth.setUser({ ...user, ...additionalData });
        } else {
          stores.auth.setUser(user);
        }
      }
    });
  }, []);
  return (
    <Provider {...stores}>
      {children}
      <ToastContainer />
    </Provider>
  );
};

export default RootLayout;
