import {makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import AuthUserService from "../services/AuthUserService";

export class AuthStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "auth",
      properties: ["user", "isAnonymous", "isAuthenticated", "displayName", "locale"],
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  user = null;

  isAnonymous = false;

  isAuthenticated = false;

  displayName = "";

  locale = "bg";

  async set(name, value) {
    this[name] = value;
  }

  setUser(user) {
    if (user) {
      this.isAnonymous = !!user.isAnonymous;
      this.user = user;
      this.isAuthenticated = user && !user.isAnonymous; //&& user.emailVerified;
      this.displayName = user.displayName;
    } else {
      this.user = null;
      this.isAnonymous = false;
      this.isAuthenticated = false;
      this.displayName = "";
    }
  }

  async convertAnonymous(email, password, displayname) {
    const convertedUser = await AuthUserService.convertAnonymous(email, password, displayname);
    await this.setUser(convertedUser);
  }

  async logout() {
    await AuthUserService.logOut();
    this.setUser();
  }
}

const auth = new AuthStore();
export default auth;
