import * as React from "react";
import _get from "lodash.get";
import _find from "lodash.find";
import { useState } from "react";
import logo from "../../../images/logo3.jpg";
import { Link, navigate } from "gatsby";
import routesConfig from "../../../routes/routesConfig";
import Button from "../../Button";
import { info, primary } from "../../../utils/colors";
import "./Navbar.sass";
import withMultipleDropdowns from "../../HOC/withMultipleDropdowns";
import products from "../../../config/products";
import { inject, observer } from "mobx-react";
import { success as successAlert } from "../../../utils/notifications";

const Navbar = props => {
  const [toggle, setToggle] = useState(false);
  const { isAuthenticated, user } = props.auth;

  const logout = async () => {
    await props.auth.logout();
    successAlert("logged out successfully!");
    return await navigate(routesConfig.home.route);
  };
  const isProfileDropdownOpen = _get(_find(props.dataStatuses, { id: "profileDropdown" }), "opened");
  return (
    <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <button
          type="button"
          className="navbar-item p-0"
          onClick={async () => {
            await navigate(routesConfig.home.route);
          }}
        >
          <img src={logo} width="52" height="52" alt="boat logo" />
        </button>

        <button
          onClick={() => setToggle(!toggle)}
          type="button"
          className={`navbar-burger ${toggle ? "is-active" : ""}`}
          aria-label={toggle ? "Close navigation menu" : "Open navigation menu"}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={`navbar-menu ${toggle ? "is-active" : ""}`}>
        <div className="navbar-start">
          {/*<a className="navbar-item">Начало</a>*/}
          {products
            .filter(p => !p.disabled)
            .map(product => (
              <Link key={product?.id} className="navbar-item" to={`${product?.link}`}>
                {product?.title}
              </Link>
            ))}
          {/*<a className="navbar-item">Дестинации</a>*/}
        </div>

        <div className="navbar-end">
          {isAuthenticated && (
            <div className="navbar-item has-dropdown is-hoverable">
              <button type="button" className="navbar-link is-size-4" onClick={() => props.toggle("profileDropdown")}>
                {_get(user, "email")}
              </button>
              <div className={`navbar-dropdown ${isProfileDropdownOpen ? "is-active" : "is-hidden-tablet is-hidden-mobile"}`}>
                <button
                  type="button"
                  className="navbar-item"
                  onClick={async () => {
                    await navigate(routesConfig.profile.route);
                  }}
                >
                  Моят профил
                </button>
                {/*<a className="navbar-item">Контакти</a>*/}
                <hr className="navbar-divider" />
                <button type="button" className="navbar-item" onClick={async () => await logout()}>
                  Изход
                </button>
              </div>
            </div>
          )}

          {!isAuthenticated && (
            <div className="navbar-item">
              <div className="buttons">
                <Button text="Регистрация" bgColor={primary} onClick={() => navigate(routesConfig.signUp.route)} />
                <Button text="Вход" bgColor={info} onClick={() => navigate(routesConfig.login.route)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
export default inject("workflow", "auth")(observer(withMultipleDropdowns(Navbar, [{ id: "profileDropdown" }])));
