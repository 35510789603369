// extracted by mini-css-extract-plugin
export var black = "#000";
export var blue = "#0093dd";
export var blueDark = "#0a75c1";
export var danger = "#d70505";
export var grey = "#5b6070";
export var greyDark = "#2e2e2e";
export var greyLight = "#9f9f9f";
export var greyLighter = "#dcdcdc";
export var greyLightest = "#f2f2f2";
export var info = "#13746a";
export var moneyGreen = "#05a98c";
export var primary = "#0a75c1";
export var success = "#5baf42";
export var warning = "#ffc100";
export var white = "#fff";