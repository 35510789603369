import routesConfig from "../routes/routesConfig";
import { getProductName } from "../utils/helpers";

const products = [
  {
    id: "laroca",
    order: 1,
    title: getProductName("laroca"),
    subtitle: "време: 4-5 часа",
    description: `${getProductName(
      "laroca"
    )} е най-добрият начин да изпитате чудесата на Nea Peramos и неговите топ атракции, включително неговите емблематични плажове и забележителности. Опитай какво е усещането от гмуркането, потопи се в истинската страст
на кристално чистите сини води и се насладете на вълнението от ездата на вълните с моторната ни лодка.`,
    price: "200 €",
    // promoPrice: "180 €",
    link: `${routesConfig.numberPassengers.route}/laroca`,
    infoLink: routesConfig.laroca.route,
    checklist: ["Висококачествен звук", "Кутия за лед", "Водни скокове"]
  },
  {
    id: "fidonisi",
    order: 2,
    title: getProductName("fidonisi"),
    subtitle: "време: 4-5 часа",
    description: `${getProductName(
      "fidonisi"
    )}  е най-добрият начин да изпитате чудесата на Nea Peramos и неговите топ атракции, включително неговите емблематични плажове и забележителности. Опитай какво е усещането от гмуркането, потопи се в истинската страст
на кристално чистите сини води и се насладете на вълнението от ездата на вълните с моторната ни лодка.`,
    price: "200 €",
    // promoPrice: "Цена: 200 €",
    link: `${routesConfig.numberPassengers.route}/fidonisi`,
    infoLink: routesConfig.fidonisi.route,
    checklist: ["Висококачествен звук", "Кутия за лед", "Водни скокове"]
  },
  {
    id: "vrasidas",
    order: 3,
    title: getProductName("vrasidas"),
    subtitle: "време: 3-4 часа",
    description: `${getProductName(
      "vrasidas"
    )} е най-добрият начин да изпитате чудесата на Nea Peramos и неговите топ атракции, включително неговите емблематични плажове и забележителности. Опитай какво е усещането от гмуркането, потопи се в истинската страст
на кристално чистите сини води и се насладете на вълнението от ездата на вълните с моторната ни лодка.`,
    price: "120 €",
    // promoPrice: "Цена: 120 €",
    link: `${routesConfig.numberPassengers.route}/vrasidas`,
    infoLink: routesConfig.vrasidas.route,
    checklist: ["Висококачествен звук", "Кутия за лед", "Водни скокове"]
  },
  {
    id: "elies",
    order: 4,
    title: getProductName("elies"),
    subtitle: "време: 3-4 часа",
    description: `${getProductName(
      "elies"
    )} е най-добрият начин да изпитате чудесата на Nea Peramos и неговите топ атракции, включително неговите емблематични плажове и забележителности. Опитай какво е усещането от гмуркането, потопи се в истинската страст
на кристално чистите сини води и се насладете на вълнението от ездата на вълните с моторната ни лодка.`,
    price: "150 €",
    // promoPrice: "Цена: 150 €",
    link: `${routesConfig.numberPassengers.route}/elies`,
    infoLink: routesConfig.elies.route,
    checklist: ["Висококачествен звук", "Кутия за лед", "Водни скокове"]
  }
];
export default products;
