import flows from "./workflows";
import _get from "lodash.get";
import matchPath from "./matchPath";
import generatePath from "./generatePath";

const routesConfig = {
  home: {
    route: "/"
  },
  profile: {
    route: "/profile"
  },
  laroca: {
    route: "/boat-trip-to-laroca"
  },
  fidonisi: {
    route: "/boat-trip-to-fidonisi"
  },
  vrasidas: {
    route: "/boat-trip-to-vrasidas"
  },
  elies: {
    route: "/boat-trip-to-elies-beach"
  },
  faq: {
    route: "/faq"
  },
  numberPassengers: {
    route: "/workflow/number-of-passengers",
    params: "/:productId",
    component: "NumberPassengers"
  },
  boatInfo: {
    route: "/boat-info"
  },
  orderContacts: {
    route: "/workflow/contacts",
    component: "OrderContacts"
  },
  review: {
    route: "/workflow/review",
    component: "Review"
  },
  message: {
    route: "/workflow/message",
    component: "Message"
  },
  date: {
    route: "/workflow/choose-date",
    component: "ChooseDate"
  },
  login: {
    route: "/login"
  },
  forgottenPass: {
    route: "/forgotten-password"
  },
  signUp: {
    route: "/sign-up"
  },
  allReservations: {
    route: "/all-reservations"
  },
  mapOfNeaPeramos: {
    route: "/map-of-nea-peramos"
  },
  riva: {
    route: "/beaches/riva-beach-project"
  },
  liquid: {
    route: "/beaches/liquid-beach-bar"
  },
  larocaBeachBar: {
    route: "/beaches/laroca-beach-bar"
  },
  breeze: {
    route: "/beaches/breeze-beach-bar"
  },
  koo: {
    route: "/beaches/koo-beach-bar"
  },
  paradise: {
    route: "/beaches/paradise-beach-bar"
  },
  ammos: {
    route: "/beaches/ammos-beach-bar"
  },
  peponi: {
    route: "/beaches/peponi-beach-bar"
  },
  blue: {
    route: "/beaches/blue-beach-bar"
  },
  eliesBeach: {
    route: "/beaches/elies-beach"
  },
  kabata: {
    route: "/beaches/kabata-beach"
  },
  vrasidasBeach: {
    route: "/beaches/vrasidas-beach"
  },
  gefyraki: {
    route: "/beaches/gefyraki-beach-bar"
  },
  ramos: {
    route: "/beaches/ramos-beach-bar"
  },
  boatTrip: {
    route: "/"
  },
  notAuthorized: {
    route: "/not-authorized"
  },
  error: {
    route: "/error"
  }
};

export const generateFlow = ({ workflow, auth }) => {
  const product = _get(workflow, "product", "");
  const flowName = _get(workflow, "flow");
  let generateFlowsForProduct = _get(flows, `[${product}]`, () => {});
  const generatedFlow = _get(generateFlowsForProduct({ auth, workflow }), `[${flowName}]`, null);
  if (!generatedFlow) {
    throw new Error("Flow is missing!");
  }
  return generatedFlow;
};

export const goToNextStep = ({ workflow, auth, location, navigate }, data = null, isReplace) => {
  const currentFlow = generateFlow({ workflow, auth });
  // console.log("currentFlow", currentFlow);

  const fullPathnameWithParams = _get(location, "pathname");
  let currentStepKey = {};
  let stepObject = {};
  let matches = [];
  if (!currentFlow) {
    throw new Error("Flow is missing!");
  }
  for (const [stepKey, step] of Object.entries(currentFlow)) {
    stepObject = step;

    if (stepObject) {
      const match =
        matchPath(fullPathnameWithParams, {
          path: `${stepObject.route}${stepObject.params || ""}`,
          exact: true,
          strict: false
        }) || {};
      if (Object.keys(match).length) {
        matches = [...matches, stepKey];
      }
    } else {
      console.log(`%cWARNING -> ${stepKey} is undefined`, "color: red");
    }
  }

  if (matches.length === 1) {
    currentStepKey = _get(matches, "[0]");
  }
  if (matches.length > 1 && _get(data, "screenId")) {
    currentStepKey = matches[parseInt(_get(data, "screenId"), 10) - 3];
  }
  const nextObjByKey = (db, key) => {
    const keys = Object.keys(db);
    const i = keys.indexOf(key);
    return i !== -1 && keys[i + 1] && db[keys[i + 1]];
  };
  const nextRoute = nextObjByKey(currentFlow, currentStepKey);
  let path = "";
  if (nextRoute) {
    const matchNextRoute = matchPath(`${nextRoute.route}/1/2/3`, {
      path: `${nextRoute.route}${nextRoute.params || ""}`,
      exact: false,
      strict: false
    });
    const paramsObject = {};

    Object.keys(_get(matchNextRoute, "params", {})).forEach(p => {
      paramsObject[p] = _get(data, `${p}`, "");
    });
    path = generatePath(`${nextRoute.route}${nextRoute.params || ""}`, paramsObject);
    if (isReplace) {
      return navigate(path, { replace: true });
    }
    return navigate(path);
  }
  return path;
};

export const routesArray = Object.values(routesConfig).map(({ route }) => route);
export const routesObjects = [];
Object.keys(routesConfig).forEach(routeKey => {
  routesObjects.push({ key: routeKey, ...routesConfig[routeKey] });
});
export default routesConfig;
