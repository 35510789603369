import React from "react";
import "../../../styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../../styles/toastify.scss";
import Navbar from "../Navbar";
import Button from "../../Button";
import { Link, navigate } from "gatsby";
import { goToNextStep } from "../../../routes/routesConfig";
import routesConfig from "../../../routes/routesConfig";
import { inject, observer } from "mobx-react";
import { useLocation } from "@reach/router";

const PageLayout = props => {
  const {
    auth,
    workflow,
    title,
    subtitle,
    titleIcon,
    btnContinue,
    btnContinueTxt,
    btnSubmit,
    btnDisabled,
    children,
    continueFunc,
    error,
    errorTitle,
    errorMessage,
    loading,
    centerContent,
    btnStyle = {}
  } = props;
  const location = useLocation();

  const content = (
    <>
      {children}
      {btnContinue && (
        <div className="has-text-centered">
          <Button
            disabled={btnDisabled}
            text={btnContinueTxt || "Продължи"}
            type={btnSubmit ? "submit" : "button"}
            style={{
              marginLeft: 10,
              marginTop: 40,
              marginBottom: 20,
              ...btnStyle
            }}
            onClick={continueFunc ? continueFunc : () => goToNextStep({ auth, workflow, location, navigate })}
          />
        </div>
      )}
    </>
  );
  return (
    <>
      <Navbar />
      {loading ? (
        <progress className="progress is-small is-success" max="100">
          5%
        </progress>
      ) : (
        <div className="p-4" style={{ minHeight: "50vh" }}>
          {error ? (
            <div className="is-flex is-flex-direction-column is-justify-content-center">
              <div />
              <span className="bold-txt is-uppercase is-size-3 has-text-centered has-text-primary">{errorTitle || "Опа, има проблем!"}</span>
              <span
                className="light-txt is-size-4 has-text-centered"
                style={{
                  marginTop: 15
                }}
              >
                {errorMessage || ""}
              </span>

              <Button
                type="button"
                text="Назад"
                style={{
                  width: 150
                }}
                onClick={() => {
                  console.log("go back"); //todo
                  // navigate(-1);
                }}
              />
            </div>
          ) : (
            <>
              {title && (
                <h1 className="has-text-centered has-text-primary mt-5 is-size-2 is-size-4-touch">
                  {titleIcon}
                  {title}
                </h1>
              )}
              {subtitle && (
                <p
                  className="light-text is-size-3 is-size-5-touch mb-5 has-text-centered"
                  style={{
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {subtitle}
                </p>
              )}
              {centerContent ? <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">{content}</div> : content}
            </>
          )}
        </div>
      )}
      <footer className="footer" style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <div className="content has-text-centered">
          <div className="container is-hidden-touch">
            <div className="columns is-mobile ">
              <div className="column has-text-left">
                <h1 className="is-size-2 is-size-4-mobile">Boattrip</h1>
                Nea Peramos, Greece
                <br />
                +359 895 776 818 <br />
                +30 694 406 7089
                <br /> 08:00 - 20:00, 7 дни в седмицата
                <br />
                boattrip2022@gmail.com
              </div>
              <div className="column has-text-left">
                <h1 className="is-size-2 is-size-4-mobile">Социални мрежи</h1>
                <a className="is-block" href="https://www.facebook.com/boatTripNeaPeramos" target="_blank" rel="noreferrer">
                  Фейсбук страница
                </a>
              </div>
              <div className="column has-text-left">
                <h1 className="is-size-2 is-size-4-mobile">Помощ</h1>
                <Link className="is-block" to={routesConfig.faq.route}>
                  Често задавани въпроси
                </Link>
                <Link className="is-block" to={routesConfig.mapOfNeaPeramos.route}>
                  Карта на Неа Перамос
                </Link>
                <Link className="is-block" to={routesConfig.boatInfo.route}>
                  Информация за лодката
                </Link>
                <a className="is-block" href="#">
                  Правила и общи условия
                </a>
              </div>
            </div>
          </div>
          <div className="container is-hidden-desktop">
            <div className="columns is-mobile ">
              <div className="column has-text-left">
                <h1 className="is-size-2 is-size-4-mobile">Boattrip</h1>
                Nea Peramos, Greece
                <br />
                +359 895 776 818 <br />
                +30 694 406 7089
                <br /> 08:00 - 20:00, 7 дни в седмицата
                <br />
                boattrip2022@gmail.com
                <h1 className="is-size-2 is-size-4-mobile">Социални мрежи</h1>
                <a className="is-block" href="https://www.facebook.com/boatTripNeaPeramos" target="_blank" rel="noreferrer">
                  Фейсбук страница
                </a>
              </div>
              <div className="column has-text-left">
                <h1 className="is-size-2 is-size-4-mobile">Помощ</h1>
                <Link className="is-block" to={routesConfig.faq.route}>
                  Често задавани въпроси
                </Link>
                <Link className="is-block" to={routesConfig.mapOfNeaPeramos.route}>
                  Карта на Неа Перамос
                </Link>
                <Link className="is-block" to={routesConfig.boatInfo.route}>
                  Информация за лодката
                </Link>
                <a className="is-block" href="#">
                  Правила и общи условия
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default inject("workflow", "auth")(observer(PageLayout));
