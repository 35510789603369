import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
  signInWithCustomToken
} from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyBpgQZZ5DXeG-qiaUWzN7aL4U9IWI_DKGE",
  authDomain: "boattrip-778b2.firebaseapp.com",
  databaseURL: "https://boattrip-778b2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "boattrip-778b2",
  storageBucket: "boattrip-778b2.appspot.com",
  messagingSenderId: "525339051751",
  appId: "1:525339051751:web:8ef1d65315adc5e1640442",
  measurementId: "G-6CNV929B0R"
};

const app = initializeApp(config);
const auth = getAuth(app);
// getAnalytics(app);

export default {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
  signInWithCustomToken
};
