import React from "react";
import { greyLight } from "../../utils/colors";
import "./Button.sass";

const Button = ({ type, onClick, text, disabled, bgColor, style, sizeClass }) => {
  let btnDesktopStyle = { backgroundColor: disabled ? greyLight : bgColor || "" };
  if (style) {
    btnDesktopStyle = { ...btnDesktopStyle, ...style };
  }
  return (
    <>
      <button type={type} style={btnDesktopStyle} className={`button is-info ${sizeClass || "is-small"}`} onClick={disabled ? () => {} : onClick}>
        <span className={`is-uppercase ${sizeClass === "is-large" ? "extra-bold-txt is-size-4 is-size-6-mobile" : "regular-txt is-size-5 is-size-6-mobile"}`}>{text}</span>
      </button>
    </>
  );
};
export default Button;
